<template>
  <div class="myhome">
      <Nav></Nav>
      <div class="mynodeCon">
          <div class="con1 cursor-pointer" @click="JumpNode">
            <div></div>
            <div>{{lang('fanhui')}}</div>
          </div>
          <div class="con2">
            <p><b>{{lang('canxuanjiedian')}}</b></p>
            <p v-if="oTime!='end'">{{lang('julicanxuanjeishuhaiyou')}} : {{oTime}}</p>
            <p v-if="oTime=='end'">{{lang('julicanxuanjeishuhaiyou')}} : {{lang('yijieshu')}}</p>
          </div>
          <div class="con3">
            <van-field
              v-model="nodename"
              error
              required
              :label="lang('jiedianmingcheng')"
              :placeholder="lang('shurujeidianmc')"
            />
             <van-field
             v-model="nodeslogan"
              error
              required
              :label="lang('jiediankouhao')"
              :placeholder="lang('shurujiediankuhao')"
            />
             <van-field
             v-model="nodeAll"
             type="number"
              error
              required
              :label="lang('fenpeibili')"
              right-label="lang('fenpeibili')"
              :placeholder="lang('shurubili')"
            >
            <template #button>%</template>
             </van-field>
             <van-field
             v-model="pleNum"
             type="number"
              error
              required
              :label="lang('diyadaibishu')"
              :placeholder="lang('shurudaibi')"
            >
            <template #button>{{lang('keyongyue')}} :{{nftNum}}TFI </template>
             </van-field>
             <div class="btn cursor-pointer" v-if="getAllowanceNum <= 0"  @click="getNodeApprove">{{lang('a_shouquan')}}</div>
             <div class="btn btn1 cursor-pointer" v-if="getAllowanceNum > 0" @click="partIn">{{lang('querencanxuan')}}</div>
          </div>
          <div class="con4">
            <p>{{lang('canxuanjiedianguize')}}</p>
            <div class="cont">
              <p>1.{{lang('jiediancanxuanguizeone')}}</p>
              <p>2.{{lang('jiediancanxuanguizetwo')}}</p>
              <p>3.{{lang('jiediancanxuanguizethree')}}</p>
              <p>4.{{lang('jiediancanxuanguizefour')}}</p>
            </div>
          </div>
      </div>
  </div>
</template>
<script>
  import Nav from '../../components/Nav/Index'
    import { lang, Toast,checkAdress } from "../../tools";
    import { formatDate } from "../../tools/index";
    import * as metaMaskTools from "../../tools/chain";
    import { mixin1, mixin2, mixin3 } from "../../tools/mixins";
  export default {
    name: 'partInNode',
    components: {Nav},
    mixins: [mixin1, mixin2, mixin3],
    mounted() {
    },
    beforeDestroy() {
      //清除定时器
      clearTimeout(this.setTime);
      clearTimeout(this.setTimeOnly);
    },
    data() {
          return {
            nodeslogan:'',
            nodeAll:'',
            pleNum:'',
            nodename:'',
            setTime: null,
            setTimeOnly: null,
            nftNum:0,
            getAllowanceNum:0,
            time:'',
            oTime:"",
            partedIn:false,
            fShow:true,
            getMyList:[],
            myObj:0

          }
      },
      computed: {
        account() {
          return this.$store.state.accounts[0];
        },
      },
      created() {
    this.$route.params.flagShowVal1=true
  },
      methods: {
        initPage() {
          this.getbalanceOfNFT()
          this.getAllowance()
          this.getCountdown()
          this.getMyNodeList()
          this.getNodeMyInfo()
        },
        initData() {
          // this.getNodeInfo1()
        },
        JumpNode() {
            this.$router.push('/node');
        },
        onRefresh(accounts) {
            this.initPage()
        },
        a(){
          if(this.time){
          this.oTime = formatDate(this.time * 1000);
          }
        },
    
        async getCountdown(format,res) {
          var res = await metaMaskTools.getCountdown(this.account);
          this.time=res
          this.timer=setInterval(this.a,1000)     
        },
        async getbalanceOfNFT() {
          var res = await metaMaskTools.getbalanceOfNFT(this.account);
          this.nftNum=this.BigNumberStr(res, 18);
        },
        async getMyNodeList() {
          this.fShow = this.$route.params.flagShowVal
          var res = await metaMaskTools.getMyNodeList(this.account,this.fShow);
          this.getMyList=res
      },
        async getNodeMyInfo() {
        var res = await metaMaskTools.getNodeMyInfo(this.account);
        this.myObj=res.depositAmount
      },
        async partIn() {
         var re = /^[1-9]+[0-9]*]*$/; 
         if(this.nodename.length>30 ||this.nodeslogan.length>50) {
           Toast(this.lang('shuruzishuchaochuxianzhi'))
           return
         }
         if(this.nodename== ''||this.nodeslogan==''||this.nodeAll=='' ||this.pleNum=='') {
           Toast(this.lang('xinxitianxiebueanzheng'))
           return
         }
         if(!re.test(this.nodeAll)) {
           Toast(this.lang('bukeshuruxiaoshu'))
           return
         }
         if(this.nodeAll/1>100 || this.nodeAll/1<50) {
           Toast(this.lang('qingshuruguidingfanweineibili'))
           return
         }
         if(this.pleNum<2100) {
           Toast(this.lang('shuridaibixiaoyuzuixiao'))
           return
         }
         if(this.pleNum/1>this.nftNum/1) {
           Toast(this.lang('zhanghuyuebuzu'))
         }
          // if(this.nodename.length>30 ||this.nodename== '' ||this.nodeslogan=='' ||this.nodeslogan.length>50 ||this.nodeAll=='' ||this.nodeAll/1>100 ||this.nodeAll/1<50 || this.pleNum=='' || this.pleNum<2100 || !re.test(this.nodeAll)) {
          //   Toast(this.lang('tishixinxi'))
          //   return
          // } else {
            if(this.nftNum<2100) {
              Toast(this.lang('zhanghuyuebuzu'))
            } else {
              if(this.myObj!=0) {
                Toast(this.lang('bukechongfu'))
                return
              } else {
                if(this.oTime=='end') {
                  Toast(this.lang('jingxuanjieshu'))
                  return
                } else {
                  var res=await metaMaskTools.getNodePartIn(this.account,this.nodename,this.nodeslogan,this.nodeAll,this.pleNum);
                  Toast(this.lang('canxuanchengong'))
                  this.$router.push('/node');
                }
                
              }
              
            }
            
          // }
          
        },
        async getAllowance() {
          var res=await metaMaskTools.getNodeAllowance(this.account);
          this.getAllowanceNum=res
        },
        async getNodeApprove() {
          await metaMaskTools.getNodeApprove(this.account);
          Toast(this.lang('a_shouquanchenggong'))
          this.getAllowance()
        },
        lang(key){
            return lang(key);
        },
        
    },
    beforeDestroy(){clearInterval(this.timer)}
  }

</script>

<style scoped lang="less">
.cursor-pointer{
  cursor: pointer;
}
@media (min-width: 769px) {  .mynodeCon {
    box-sizing: border-box ;
    width: 100%;
    padding: 110px 40px 0 240px;
      .con1 {
          position: fixed;
          z-index: 40;
          width: 80px;
          height: 28px;
          line-height: 25px;
          border-radius: 14px;
          border: 1px solid #fff;
          background-color: #152e55;
          color: #fff;
          padding: 0 10px;        
          div:nth-of-type(1) {
            width: 12px;
            height: 12px;
            background: url('../../assets/img/fenhui.png') no-repeat;
            background-size: 100% 100%;
            color: #fff;
            display: inline-block;
            vertical-align:middle;
            margin-right: 10px;
          }
          div:nth-of-type(2) {
            display: inline-block;
            font-size: 12px;
            vertical-align:middle;
          }
          
      }
      .con2 {
            z-index: 40;
            position: fixed;
            margin-top: 60px;
            color: #fff;
            p:nth-of-type(2) {
              font-size: 12px;
              margin-top: 8px;
            }
      }
      .con3 {
        width: 100%;
        margin-top: 130px;
        height: 282px;
        background-color: rgba(#0F111F,.4);
        border-radius: 10px;
        .van-field {
          background: transparent;
          line-height: 30px;
          z-index: 0 !important;
        }
        /deep/ .van-field__control::placeholder  {
          color: rgba(#fff,.6);
          font-size: 12px;
        }
        /deep/ .van-field__label {
          width: auto;
          color: #fff;
          font-size: 14px;
        }
        .btn {
          width: 75px;
          height: 25px;
          padding: 0 10px;
          line-height: 25px;
          font-size: 12px;
          margin: 30px auto;
          background: linear-gradient( #0077C5 , #0045BB);
          border-radius: 12.5px;
          color: #fff;
          text-align: center;
        }
        .btn1 {
          width: 140px;
        }
      }
      .con4 {
        margin-top: 30px;
        width: 100%;
        color: #fff;
        font-size: 12px;
        .cont {
          height: 146px;
          padding: 14.5px 21px;
          background-color: rgba(#0F111F,.4);
          margin-top: 20px;
          border-radius: 10px;
          p {
            line-height: 20px;
          }
        }
      }
  }}

  @media (max-width: 768px){
    .mynodeCon {
    box-sizing: border-box ;
    width: 100%;
    padding: 10px 10px 0 ;
      .con1 {
          margin-top: 20px;
          text-align: center;
          width: 80px;
          height: 28px;
          line-height: 25px;
          border-radius: 14px;
          border: 1px solid #fff;
          background-color: #152e55;
          color: #fff;
          padding: 0 10px;        
          div:nth-of-type(1) {
            width: 12px;
            height: 12px;
            background: url('../../assets/img/fenhui.png') no-repeat;
            background-size: 100% 100%;
            color: #fff;
            display: inline-block;
            vertical-align:middle;
            margin-right: 10px;
          }
          div:nth-of-type(2) {
            display: inline-block;
            font-size: 12px;
            vertical-align:middle;
          }
          
      }
      .con2 {
            margin-top: 20px;
            color: #fff;
            p:nth-of-type(2) {
              font-size: 12px;
              margin-top: 8px;
            }
      }
      .con3 {
        width: 100%;
        margin-top: 20px;
        height: 282px;
        background-color: rgba(#0F111F,.4);
        border-radius: 10px;
        .van-field {
          background: transparent;
          line-height: 30px;
        }
        /deep/ .van-field__control::placeholder  {
          color: rgba(#fff,.6);
          font-size: 12px;
        }
        /deep/ .van-field__label {
          width: auto;
        }
        .btn {
          width: 75px;
          height: 25px;
          line-height: 25px;
          font-size: 12px;
          margin: 30px auto;
          background: linear-gradient( #0077C5 , #0045BB);
          border-radius: 12.5px;
          color: #fff;
          text-align: center;
        }
        .btn1 {
          width: 1.40rem;
        }
      }
      .con4 {
        margin: 30px 0 20px;
        width: 100%;
        color: #fff;
        font-size: 12px;
        .cont {
          height: 146px;
          padding: 14.5px 21px;
          background-color: rgba(#0F111F,.4);
          margin-top: 20px;
          border-radius: 10px;
          p {
            line-height: 20px;
          }
        }
      }
  }
  }

</style>
